body {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #1c0a2e;
  color: white;
}

.section {
  width: 100%;
  padding: 20px 0;
  margin: 10px 0;
  border-radius: 10px;
}

.gradient-section {
  background: linear-gradient(135deg, #6A5ACD, #4B0082);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#additional-messages {
  background-color: #4B0082;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
}

#additional-messages h3 {
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.button {
  display: inline-block;
  padding: 12px 24px;
  margin: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
}

h2, h3 {
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.header {
  width: 100%;
  background-color: #1e0836;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image {
  max-width: 100%;
  height: auto;
}

#contact-and-follow {
  padding: 30px 0;
  margin-top: 30px;
}

#contact-and-follow .container {
  flex-direction: row;
  justify-content: center;
}

#contact-and-follow .button {
  margin: 0 15px;
}

.contact-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover,
.contact-link:active {
  color: #9370DB;
}